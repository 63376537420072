import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { FetchPolicy } from '@core/enums/fetch-policy-apollo.enum';
import { OrderFilter, OrderListResponse, OrderResponse } from '@core/interfaces/order-reference-guide.interface';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private apollo: Apollo) {}

  public getListOrder(
    pagination: { limit: number; offset: number } = { limit: 20, offset: 0 },
    filter: OrderFilter = {},
  ): Observable<ApolloQueryResult<OrderListResponse>> {
    return this.apollo.query<OrderListResponse>({
      query: gql`
        query GetListOrder($filter: OrderFilterDto, $pagination: OffsetPaginationInput) {
          getListOrder(filter: $filter, pagination: $pagination) {
            result {
              id
              createdAt
              updatedAt
              deliveryDate
              deliveryTax
              orderAmount
              recipientAddress
              recipientData {
                name
                address {
                  addressString
                }
                comment
                name
                phone
              }
              recipientPhone
              senderAddress
              senderData {
                name
                address {
                  addressString
                }
                comment
                name
                phone
              }
              senderPhone
              status
              timeWindow
              logs
              requestId
              zone {
                name
                id
              }
              deliveryPoint
            }
            total
          }
        }
      `,
      variables: {
        filter,
        pagination,
      },
      fetchPolicy: FetchPolicy.NoCache,
    });
  }

  public getOrder(id: number): Observable<ApolloQueryResult<OrderResponse>> {
    return this.apollo.query<OrderResponse>({
      query: gql`
        query GetOrder($id: Int!) {
          getOrder(id: $id) {
            id
            createdAt
            updatedAt
            deliveryDate
            deliveryTax
            orderAmount
            orderItems {
              productId
              quantity
              price
              productId
              productName
              width
              height
              depth
              weight
            }
            recipientAddress
            recipientPhone
            recipientData {
              name
              phone
              comment
              address {
                addressString
                lat
                lng
              }
            }
            senderAddress
            senderPhone
            senderData {
              name
              phone
              comment
              address {
                addressString
                lat
                lng
              }
            }
            status
            timeWindow
            logs
            requestId
          }
        }
      `,
      variables: { id },
    });
  }
}
