/* eslint-disable */

import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { OrderService } from '@core/services/order-reference-guide.service';
import { Observable, tap } from 'rxjs';
import { Order, OrderListResponse, OrderResponse } from '@core/interfaces/order-reference-guide.interface';
import { formatDate, formatDateWithoutTime, formatTime } from '@core/helper/request-time-window-helper';
import { ApolloQueryResult } from '@apollo/client/core';
import { GetListOrderReferenceGuide, GetOrder } from '../action/order-reference-guide.action';

export interface OrderReferenceGuideStateModel {
  orderList: Order[];
  order: Order | null;
  total: number | null;
}

@State<OrderReferenceGuideStateModel>({
  name: 'orderReferenceGuide',
  defaults: {
    orderList: [],
    order: null,
    total: null,
  },
})
@Injectable()
export class OrderReferenceGuideState {
  constructor(private orderService: OrderService) {}

  @Selector()
  static getOrderReferenceGuides(state: OrderReferenceGuideStateModel): Order[] {
    return state.orderList;
  }

  @Selector()
  static getOrder(state: OrderReferenceGuideStateModel): Order | null {
    return state.order;
  }

  @Selector()
  static getTotalOrderReferenceGuides(state: OrderReferenceGuideStateModel): number | null {
    return state.total;
  }

  @Action(GetListOrderReferenceGuide)
  public getListOrder(
    ctx: StateContext<OrderReferenceGuideStateModel>,
    { pagination, filter }: GetListOrderReferenceGuide,
  ): Observable<ApolloQueryResult<OrderListResponse>> {
    return this.orderService.getListOrder(pagination, filter).pipe(
      tap((res) => {
        const transformedOrders = res.data.getListOrder.result.map((order) => ({
          ...order,
          createdAt: formatDateWithoutTime(order.createdAt),
          deliveryDate: formatDateWithoutTime(order.deliveryDate),
          timeWindow: formatTime(order.timeWindow),
        }));
        ctx.patchState({
          orderList: transformedOrders,
          total: res.data.getListOrder.total,
        });
      }),
    );
  }

  @Action(GetOrder)
  public getOrder(ctx: StateContext<OrderReferenceGuideStateModel>, { id }: GetOrder) {
    return this.orderService.getOrder(id).pipe(
      tap((res) => {
        const transformedOrder = {
          ...res.data.getOrder,
          createdAt: formatDateWithoutTime(res.data.getOrder.createdAt),
          deliveryDate: formatDateWithoutTime(res.data.getOrder.deliveryDate),
          timeWindow: formatTime(res.data.getOrder.timeWindow),
          orderItems: res.data.getOrder.orderItems.map(({ price, quantity, ...item }) => ({
            ...item,
            price,
            quantity,
            sumOfProducts: price * quantity,
          })),
        };
        ctx.patchState({ order: transformedOrder });
      }),
    );
  }
}
