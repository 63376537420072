import { OrderFilter } from '@core/interfaces/order-reference-guide.interface';
import { OffsetPagination } from '@core/interfaces/zone.interface';

export class GetListOrderReferenceGuide {
  static readonly type = '[GetListOrderReferenceGuide] Get List Order Reference Guide';

  constructor(
    public pagination: OffsetPagination,
    public filter?: OrderFilter,
  ) {}
}

export class GetOrder {
  static readonly type = '[Order] Get Order';

  constructor(public id: number) {}
}
